.App {
  padding-top: 64px;
}

.match {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px lightblue solid;
  border-radius: 4px;
  height: 100px;
}

.match-header {
  border: none;
}

.match-header img {
  height: 100px;
}

.conversion-container {
  width: 40%;
}

.candidate-container {
  width: 40%;
}

.client-id, .candidate-id {
  font-weight: lighter;
}

.source, .status {
  font-weight: bold;
  font-size: 1.5em;
}

.campaign-medium {
  display: flex;
}

.campaign {
  font-style: italic;
  margin-right: 4px;
}

.converted-at, .applied-at {
  color: grey;
  font-size: 0.9em;
}

.match-container {
  width: 60px;
}

button {
  font-weight: bolder;
  text-transform: uppercase;
  background-color: rebeccapurple;
  padding: 6px 20px;
  border-radius: 4px;
  box-shadow: none;
  color: white;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid seagreen;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: seagreen transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-container {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
